import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import logo from "../assets/no_bg_black_fino.png"
import { Flame, Terminal, Key, Users } from "lucide-react";
import { CiGlobe } from "react-icons/ci";
import axios from "axios"
import { Link } from "react-router-dom";
import { collection, getDocs, query, where, getDoc, limit, orderBy, deleteDoc, onSnapshot, addDoc, updateDoc, serverTimestamp, doc } from 'firebase/firestore';

const Deployment = ({ db, user }) => {
    const [editMode, setEditMode] = useState(false);
    const { id } = useParams();
    const [logs, setLogs] = useState([]);
    const [deleting, setDeleting] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [deploymentData, setDeploymentData] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [updatedData, setUpdatedData] = useState({
        repoUrl: '',
        webAppName: '',
        nodejsVersion: null,
        envVars: [],
        plan: '',
        portNumber: null,
        runtime: '',
        token: "",
        uid: "",
        ip: "",
        password: "",
        location: '',
        branch: null,
        deploymentId: '',
        startupCommand: ''
    });
    const [activeSection, setActiveSection] = useState('main');
    const [showPassword, setShowPassword] = useState(false);
    const [copiedSSH, setCopiedSSH] = useState(false);
    const [copiedPassword, setCopiedPassword] = useState(false);

    const menuItems = [
        { icon: Flame, text: "Main", onClick: () => setActiveSection('main') },
        { icon: Terminal, text: "Logs", onClick: () => setActiveSection('logs') },
        { icon: Key, text: "Credentials", onClick: () => setActiveSection('credentials') },
    ];

    const uid = user?.uid;

    const fetchLogs = async () => {
        try {
            const logDoc = await getDoc(doc(db, "logs", deploymentData?.data?.deploymentId));

            if (logDoc.exists()) {
                const allLogs = logDoc?.data()?.logs || [];

                const deploymentLogs = allLogs
                    .filter(log => log.userId === uid)
                    .sort((a, b) => {
                        // Handle Firestore Timestamp objects
                        const timeA = a.timestamp?.toMillis?.() || 0;
                        const timeB = b.timestamp?.toMillis?.() || 0;
                        return timeB - timeA;
                    })
                    .slice(0, 20);

                setLogs(deploymentLogs);
            } else {
                setLogs([]);
            }
        } catch (error) {
            console.error("Error fetching logs:", error);
        }
    };

    useEffect(() => {
        const fetchDeployment = async () => {
            try {
                const deploymentDoc = await getDoc(doc(db, "deployments", id));
                if (deploymentDoc.exists()) {
                    const deployment = {
                        id: deploymentDoc.id,
                        ...deploymentDoc.data()
                    };
                    setDeploymentData(deployment);
                    setUpdatedData({
                        repoUrl: deployment.data?.repoUrl || '',
                        webAppName: deployment.data?.webAppName || '',
                        nodejsVersion: deployment.data?.nodejsVersion || null,
                        envVars: deployment.data?.envVars || [],
                        plan: deployment.data?.plan || 'Beta',
                        token: deployment?.token,
                        uid: deployment?.uid,
                        ip: deployment?.ip,
                        password: deployment?.password,
                        portNumber: deployment.data?.portNumber || null,
                        runtime: deployment.data?.runtime || '',
                        location: deployment.data?.location || 'amd',
                        branch: deployment.data?.branch || null,
                        deploymentId: deployment.data?.deploymentId || '',
                        startupCommand: deployment.data?.startupCommand || ''
                    });
                    console.log("Deployment data retrieved:", deployment);
                } else {
                    console.error("No such deployment!");
                }
            } catch (error) {
                console.error("Error fetching deployment:", error);
            }
        };

        fetchDeployment();

    }, [db, id]);

    useEffect(() => {
        let unsubscribe;

        if (activeSection === 'logs') {
            unsubscribe = onSnapshot(
                doc(db, "logs", deploymentData?.data?.deploymentId),
                (doc) => {
                    if (doc.exists()) {
                        const allLogs = doc?.data()?.logs || [];
                        const deploymentLogs = allLogs
                            .filter(log => log.userId === uid)
                            .sort((a, b) => {
                                const timeA = a.timestamp?.toMillis?.() || 0;
                                const timeB = b.timestamp?.toMillis?.() || 0;
                                return timeB - timeA;
                            })
                            .slice(0, 20);

                        setLogs(deploymentLogs);
                    } else {
                        setLogs([]);
                    }
                }
            );
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [activeSection, deploymentData?.data?.deploymentId, uid]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://${deploymentData?.endpoint}`);
        setShowTooltip(true);
    };

    useEffect(() => {
        if (showTooltip) {
            const timer = setTimeout(() => setShowTooltip(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [showTooltip]);


    const deleteDeployment = async (id) => {
            console.log("Id: ", id)
            {/*
                            const subsId = deploymentData?.subscriptionId; 
            const subscriptionResponse = await fetch(`http://localhost:5000/cancel-subscription/${subsId}`, { method: 'DELETE' });
            if (!subscriptionResponse.ok) {
                throw new Error(`Failed to cancel subscription: ${subscriptionResponse.statusText}`);
            }
                */}
        try {
            setDeleting(true)
            const instanciaId = deploymentData?.instanceId;

            if (!instanciaId) {
                throw new Error('Instance ID not found in deployment data');
            }
            const deleteInstanceResponse = await fetch(`http://localhost:5000/delete/${instanciaId}/${id}`, {
                method: 'DELETE'
            });

            if (!deleteInstanceResponse.ok) {
                throw new Error(`Failed to delete instance: ${deleteInstanceResponse.statusText}`);
            }
            setDeleting(false)
            console.log("Deployment deleted successfully.");
            window.location.href = '/dashboard'; 

        } catch (error) {
            setDeleting(false)
            console.error("Error deleting deployment:", error);
            alert(`Failed to delete deployment: ${error.message}`);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSaveChanges = async (selectedDeployment) => {
        const instanceId = selectedDeployment?.instanceId;
        const deploymentId = selectedDeployment?.data?.deploymentId;
        const uid = selectedDeployment?.uid;

        if (!instanceId || !deploymentId || !uid) {
            console.error('Missing required IDs');
            return;
        }

        const changedFields = {};
        Object.keys(updatedData).forEach(key => {
            if (updatedData[key] !== selectedDeployment.data?.[key]) {
                changedFields[key] = updatedData[key];
            }
        });

        try {
            setUpdating(true)
            const response = await fetch(`http://localhost:5000/update-instance/${instanceId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...changedFields,
                    currentData: selectedDeployment.data,
                })
            });

            if (!response.ok) {
                throw new Error(`Failed to update instance: ${response.statusText}`);
            }

            const deploymentRef = doc(db, "deployments", selectedDeployment.id);
            await updateDoc(deploymentRef, {
                data: {
                    ...selectedDeployment.data,
                    ...changedFields
                },
                lastUpdated: serverTimestamp()
            });

            await addDoc(collection(db, "logs"), {
                userId: uid,
                deploymentId: deploymentId,
                logName: 'instance_updated',
                content: `Instance ${instanceId} updated successfully`,
                timestamp: serverTimestamp()
            });

            setDeploymentData(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    ...changedFields
                }
            }));

            setEditMode(false);
            setUpdating(false)

        } catch (error) {
            setUpdating(false)
            console.error('Error saving changes:', error);
            await addDoc(collection(db, "logs"), {
                userId: uid,
                deploymentId: deploymentId,
                logName: 'update_error',
                content: `Error updating instance: ${error.message}`,
                timestamp: serverTimestamp()
            });
            throw error;
        }
    };

    return(
        <div className="min-h-screen bg-gray-50">
            <header className="bg-white shadow-sm border-b">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <Link to="/dashboard" className="text-2xl font-bold text-indigo-600">
                            <img className="w-24 h-auto" src={logo} />
                        </Link>
                        <div className="flex items-center space-x-4">
                            <span className="text-gray-700">{user?.email}</span>
                        </div>
                    </div>
                </div>
            </header>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col md:flex-row min-h-screen">
                    <nav className="w-full md:w-64 py-8 flex-shrink-0">
                        <div className="space-y-1">
                            {menuItems.map((item, index) => (
                                <a
                                    key={index}
                                    onClick={item.onClick}
                                    className="flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors cursor-pointer
                                             text-gray-600 hover:bg-indigo-50 hover:text-indigo-600"
                                >
                                    <item.icon className="mr-3 h-5 w-5" />
                                    {item.text}
                                </a>
                            ))}
                        </div>
                    </nav>

                    {/* Main Content */}
                    <main className="flex-1 py-8">
                        <div className="bg-white rounded-lg shadow">
                            {activeSection === 'main' && (
                                <>
                                    {/* Header Section */}
                                    <div className="px-6 py-5 border-b border-gray-200">
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center">
                                                <CiGlobe className="w-6 h-6 text-indigo-600" />
                                                <span className="ml-2 text-lg font-medium text-gray-900">Web service</span>
                                            </div>
                                            <div className="flex space-x-4">
                                                <button
                                                    onClick={() => setEditMode(!editMode)}
                                                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
                                                >
                                                    {editMode ? 'Cancel' : 'Edit'}
                                                </button>
                                            </div>
                                        </div>
                                        <h2 className="mt-4 text-xl font-semibold text-gray-900">
                                            {deploymentData?.data?.webAppName}
                                        </h2>
                                    </div>

                                    {/* Details Section */}
                                    <div className="px-6">
                                        <dl className="divide-y divide-gray-200">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Repository</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <a href={deploymentData?.data?.repoUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
                                                        {deploymentData?.data?.repoUrl}
                                                    </a>
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Location</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {editMode ? (
                                                        <select
                                                            name="location"
                                                            value={updatedData.location}
                                                            onChange={handleInputChange}
                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        >
                                                            <option value="ams">Amsterdam (Netherlands)</option>
                                                            <option value="atl">Atlanta (Georgia, USA)</option>
                                                            <option value="ewr">New Jersey (New Jersey, USA)</option>
                                                        </select>
                                                    ) : (
                                                        updatedData.location || deploymentData?.data?.location
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Plan</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {editMode ? (
                                                        <select
                                                            name="plan"
                                                            value={updatedData.plan}
                                                            onChange={handleInputChange}
                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        >
                                                            <option value="Beta">Beta</option>
                                                            <option value="Omega">Omega</option>
                                                            <option value="Delta">Delta</option>
                                                        </select>
                                                    ) : (
                                                        updatedData.plan || deploymentData?.data?.plan
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Port number</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {editMode ? (
                                                        <input
                                                            name="portNumber"
                                                            value={updatedData.portNumber}
                                                            onChange={handleInputChange}
                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    ) : (
                                                        updatedData.portNumber || deploymentData?.data?.portNumber
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">NodeJS version</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {editMode ? (
                                                        <input
                                                            name="nodejsVersion"
                                                            value={updatedData.nodejsVersion}
                                                            onChange={handleInputChange}
                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    ) : (
                                                        updatedData.nodejsVersion || deploymentData?.data?.nodejsVersion
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Branch name</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {editMode ? (
                                                        <input
                                                            name="branch"
                                                            value={updatedData.branch}
                                                            onChange={handleInputChange}
                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    ) : (
                                                        updatedData.branch || deploymentData?.data?.branch
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Endpoint</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                                    <a
                                                        className="cursor-pointer text-indigo-500 hover:text-indigo-600 mr-2"
                                                        target="_blank"
                                                        href={`https://${deploymentData?.endpoint}`}
                                                    >
                                                        https://{deploymentData?.endpoint}
                                                    </a>
                                                    <div className="relative">
                                                        <button
                                                            onClick={copyToClipboard}
                                                            className="text-gray-400 hover:text-gray-600 focus:outline-none"
                                                            aria-label="Copy endpoint"
                                                        >
                                                            <CopyIcon />
                                                        </button>
                                                        {showTooltip && (
                                                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded shadow-lg">
                                                                Copied!
                                                            </div>
                                                        )}
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Delete web service</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <button
                                                        onClick={() => {
                                                            const id = deploymentData?.id;
                                                            const subscriptionId = deploymentData?.subscriptionId;
                                                            deleteDeployment(id);
                                                        }}
                                                        disabled={deleting}
                                                        className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
                                                    >
                                                        Delete
                                                    </button>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    {editMode && (
                                        <div className="px-6 py-4 bg-gray-50 flex justify-end">
                                            <button
                                                onClick={() => handleSaveChanges(deploymentData)}
                                                disabled={updating}
                                                className={`px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors ${updating ? 'opacity-50 cursor-not-allowed' : ''
                                                    }`}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    )}
                                </>
                            )
                            }
                            {activeSection === 'logs' && (
                                <div className="px-6 py-6">
                                    <h2 className="text-lg font-semibold text-gray-900 mb-4">Console</h2>
                                    <div className="relative">
                                        <div
                                            className="bg-gray-900 rounded-lg text-white p-4 font-mono text-sm overflow-y-auto"
                                            style={{ height: '400px', maxWidth: '100%' }}
                                        >
                                            {logs?.length === 0 ? (
                                                <p className="text-gray-400">No logs available.</p>
                                            ) : (
                                                <ul className="space-y-2">
                                                    {logs?.map((log) => (
                                                        <li key={log?.id} className="leading-relaxed">
                                                            <span className="text-green-400">{log?.logName}</span>:{' '}
                                                            <span className="text-gray-300">{log?.content}</span>{' '}
                                                            <span className="text-gray-500 text-xs">
                                                                ({new Date(log?.timestamp?.toDate()).toLocaleString()})
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeSection === 'credentials' && (
                                <div className="px-6 py-6">
                                    <h2 className="text-lg font-semibold text-gray-900 mb-4">Instance Credentials</h2>
                                    <p className="text-sm text-gray-600 mb-6">Use these credentials to connect to your instance via SSH. Keep these credentials secure and never share them.</p>

                                    <div className="bg-gray-50 p-6 rounded-lg space-y-6">
                                        <div>
                                            <p className="text-sm font-medium text-gray-700 mb-2">SSH Connection Command</p>
                                            <div className="flex items-center space-x-2">
                                                <code className="flex-1 bg-gray-900 text-white px-4 py-3 rounded font-mono text-sm">
                                                    ssh root@{deploymentData?.ip}
                                                </code>
                                                <button
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(`ssh root@${deploymentData?.ip}`);
                                                        setCopiedSSH(true);
                                                        setTimeout(() => setCopiedSSH(false), 2000);
                                                    }}
                                                    className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                                                    title="Copy SSH command"
                                                >
                                                    <CopyIcon />
                                                    {copiedSSH && (
                                                        <div className="absolute mt-2 px-2 py-1 bg-gray-800 text-white text-xs rounded">
                                                            Copied!
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </div>

                                        <div>
                                            <p className="text-sm font-medium text-gray-700 mb-2">Root Password</p>
                                            <div className="flex items-center space-x-2">
                                                <code className="flex-1 bg-gray-900 text-white px-4 py-3 rounded font-mono text-sm">
                                                    {showPassword ? deploymentData?.password : '••••••••••••••••'}
                                                </code>
                                                <button
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                                                    title={showPassword ? 'Hide password' : 'Show password'}
                                                >
                                                    {showPassword ? (
                                                        <EyeOffIcon />
                                                    ) : (
                                                        <EyeIcon />
                                                    )}
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(deploymentData?.password);
                                                        setCopiedPassword(true);
                                                        setTimeout(() => setCopiedPassword(false), 2000);
                                                    }}
                                                    className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                                                    title="Copy password"
                                                >
                                                    <CopyIcon />
                                                    {copiedPassword && (
                                                        <div className="absolute mt-2 px-2 py-1 bg-gray-800 text-white text-xs rounded">
                                                            Copied!
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="mt-6 bg-blue-50 border-l-4 border-blue-400 p-4">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <InfoIcon className="h-5 w-5 text-blue-400" />
                                                </div>
                                                <div className="ml-3">
                                                    <h3 className="text-sm font-medium text-blue-800">Connection Instructions</h3>
                                                    <div className="mt-2 text-sm text-blue-700">
                                                        <ol className="list-decimal list-inside space-y-1">
                                                            <li>Copy the SSH command above</li>
                                                            <li>Open your terminal</li>
                                                            <li>Paste and run the SSH command</li>
                                                            <li>When prompted, copy and paste the password</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Deployment;

const CopyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>
);

const EyeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
    </svg>
);

const EyeOffIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
        <line x1="1" y1="1" x2="23" y2="23"></line>
    </svg>
);

const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
);