import React from 'react';
import { Cpu, MemoryStickIcon } from 'lucide-react';
import Header from "./header"
import Footer from './footer';

const Pricing = () => {

    const pricingData = [
        { tier: 'Starter', ram: '512MB', cpu: '0.5-1', renderPrice: '$7/month', finoPrice: 'free', flyPrice: '$4.18/month' },
        { tier: 'Standard', ram: '2GB', cpu: '1', renderPrice: '$25/month', finoPrice: '$12/month', flyPrice: '$16.56/month' },
        { tier: 'Pro', ram: '4GB', cpu: '2', renderPrice: '$85/month', finoPrice: '$25/month', flyPrice: '$33.41/month' },
        { tier: 'Pro Plus', ram: '8GB', cpu: '4', renderPrice: '$175/month', finoPrice: '$50/month', flyPrice: '$133.63/month' },
        { tier: 'Pro Max', ram: '16GB', cpu: '4', renderPrice: '$225/month', finoPrice: '$99/month', flyPrice: '$133.63/month' },
        { tier: 'Pro Ultra', ram: '32GB', cpu: '8', renderPrice: '$450/month', finoPrice: '$190/month', flyPrice: '$267.26/month' }
    ];

    return (
        <div className='bg-gradient-to-r from-[#1b0537] via-[#110627] to-[#0a051d] min-h-screen'>
<Header/> 
            <div className="p-6 max-w-screen-lg pt-16 pb-32 mx-auto text-white rounded-lg">
                <h2 className="text-3xl font-bold mb-8 text-center">Fino vs Render vs Fly vs Railway</h2>
                <p className="mb-10 text-center text-lg">
                    Fino offers the cheapest PaaS option while being easy to use
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {pricingData.map((row, index) => (
                        <div key={index} className="bg-white text-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-xl font-semibold mb-2">{row.tier}</h3>
                            <p className="flex items-center mb-2"><MemoryStickIcon className="inline-block mr-1" />{row.ram} RAM</p>
                            <p className="flex items-center mb-2"><Cpu className="inline-block mr-1" />{row.cpu} CPU</p>
                            <div className="mt-4">
                                <div className="flex justify-between mb-2">
                                    <span className="text-gray-800 font-semibold">Fino: {row.finoPrice} <a className="rounded p-1 border border-green-500 text-green-500 text-xs">Cheapest</a> </span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span className="text-gray-800 font-semibold">Fly.io: {row.flyPrice}</span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span className="text-gary-800 font-semibold">Render: {row.renderPrice}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> 
            <Footer/>
</div>
    );
};

export default Pricing;  