import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Check, Mail, LayoutDashboard } from 'lucide-react';
import { Cloud, DollarSign, Cpu, MemoryStickIcon } from 'lucide-react';
import { CloudLightning, RefreshCcw, Briefcase, Package, Settings } from 'lucide-react';
import { Link } from "react-router-dom";
import logo from "../assets/no_bg_fino.png"
import Footer from "./footer";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import {
    Github,
    Globe,
    Server,
    Terminal,
    RefreshCw,
    Lock,
} from 'lucide-react';
import { Zap, Users } from 'lucide-react';

const Home = ({ signOutWithGitHub, signInWithGitHub, user}) => {

    const data = [
        { month: 'Jan', 'My Blog': 0.8, 'Company Website': 1.2, 'Side Project': 0.7 },
        { month: 'Feb', 'My Blog': 0.6, 'Company Website': 0.9, 'Side Project': 0.5 },
        { month: 'Mar', 'My Blog': 0.4, 'Company Website': 0.5, 'Side Project': 0.3 },
        { month: 'Apr', 'My Blog': 0.6, 'Company Website': 0.8, 'Side Project': 0.6 },
        { month: 'May', 'My Blog': 1.0, 'Company Website': 1.2, 'Side Project': 0.9 }
    ];

    const navigate = useNavigate();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const features = [
        {
            icon: Zap,
            title: "Build with speed",
            description: "Link your repo and go live—then iterate with every push.",
            subFeatures: [
                "Automatic Deploys",
                "Native Language Runtimes",
                "Enterprise-Grade Data Stores",
                "Private Network"
            ]
        },
        {
            icon: Cloud,
            title: "Scale with confidence",
            description: "Grow your infrastructure, team, and business with a platform that scales alongside you.",
            subFeatures: [
                "Load-Based Autoscaling",
                "Infrastructure as Code",
                "DDoS Protection",
                "Data Privacy"
            ]
        },
        {
            icon: Users,
            title: "Ship together",
            description: "Collaborate with your team on everything from one-line fixes to full-scale migrations.",
            subFeatures: [
                "Project Management",
                "Preview Environments",
                "Platform Notifications",
                "Role-Based Access"
            ]
        }
    ];

    const steps = [
        {
            icon: Globe,
            title: "Go to Fino",
            description: "Visit our website and sign up or log in to your account.",
        },
        {
            icon: Github,
            title: "Connect GitHub",
            description: "Link your GitHub account to access your repositories.",
        },
        {
            icon: Server,
            title: "Create Web Service",
            description: "Click 'Web Service' in your dashboard to start a new deployment.",
        },
        {
            icon: Terminal,
            title: "Configure Deployment",
            description: "Select your repository, set the name, location, and other details.",
        },
        {
            icon: RefreshCw,
            title: "Deploy",
            description: "Click 'Deploy' and wait for the process to complete.",
        },
        {
            icon: Lock,
            title: "Access Your App",
            description: "Use the provided HTTPS endpoint to securely access your deployed application.",
        }
    ];

    const goToDashboard = () => {
        navigate("/dashboard");
    }

    const pricingData = [
        { tier: 'Starter', ram: '512MB', cpu: '0.5-1', renderPrice: '$7/month', coolCloudPrice: 'free', awsPrice: '$4.18/month' },
        { tier: 'Standard', ram: '2GB', cpu: '1', renderPrice: '$25/month', coolCloudPrice: '$12/month', awsPrice: '$16.56/month' },
        { tier: 'Pro', ram: '4GB', cpu: '2', renderPrice: '$85/month', coolCloudPrice: '$25/month', awsPrice: '$33.41/month' },
        { tier: 'Pro Plus', ram: '8GB', cpu: '4', renderPrice: '$175/month', coolCloudPrice: '$50/month', awsPrice: '$133.63/month' },
        { tier: 'Pro Max', ram: '16GB', cpu: '4', renderPrice: '$225/month', coolCloudPrice: '$99/month', awsPrice: '$133.63/month' },
        { tier: 'Pro Ultra', ram: '32GB', cpu: '8', renderPrice: '$450/month', coolCloudPrice: '$190/month', awsPrice: '$267.26/month' }
    ];


    return ( 
        <div className="bg-gradient-to-r from-[#1b0537] via-[#110627] to-[#0a051d] min-h-screen flex flex-col">
            {/* Header */}
            <header className="w-full">
                <div className="container mx-auto flex items-center py-4 px-6">
                    <div className="flex text-white text-3xl w-1/3 flex justify-center items-center">
                        <img className="w-24 h-auto" src={logo} />
                    </div>
                    <div className="flex w-1/3 justify-evenly items-center space-x-4">
                        <a className="hidden md:flex space-x-8">
                            <Link to="/pricing" 
                                className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                                Pricing
                            </Link>
                        </a>
                        <a href="mailto:hector.diaz@pucp.edu.pe" 
                            className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                            Contact
                        </a>
                        <a target="_blank" href="https://youtu.be/49CWIrgQwjI" 
                            className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                            Tutorial
                        </a>
                        {user && (
                            <Link to="/dashboard" 
                                className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                                Dashboard
                            </Link>
                        )}
                    </div>
                    <div className="w-1/3 flex justify-center">
                            <button onClick={user ? signOutWithGitHub : signInWithGitHub} class="relative bg-transparent border-2 border-purple-800 border-r-blue-400 text-white px-6 py-2 rounded-full font-semibold transition-all hover:border-opacity-80">
                                {user ? "Logout" : "Login"}
                            </button>

                    </div>
                        <button className="md:hidden text-gray-300" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                            </svg>
                        </button>
                </div>
                {mobileMenuOpen && (
                    <nav className="md:hidden bg-[#0a051d]/95 border-b border-gray-800">
                        <div className="px-6 py-4 space-y-2">
                            <Link to="/pricing" 
                                className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                                Pricing
                            </Link>
                            <a href="mailto:hector.diaz@pucp.edu.pe" 
                                className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                                Contact
                            </a>
                            {user && (
                                <Link to="/dashboard" 
                                    className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                                    Dashboard
                                </Link>
                            )}
                        </div>
                    </nav>
                )}
            </header>

            <main className="flex-grow ">
                {/* Hero Section */}
                <section className="pt-20">
                    <div className="absolute bg-black" />
                    <div className="container mx-auto text-center px-4 relative z-10">
                        <p className="font-semibold mb-6 mx-auto text-purple-400">
                            Built for devs who ship
                        </p>
                        <p className="text-5xl font-semibold text-white sm:px-72 2xl:px-96 mb-9">
                            Your <span className="bg-gradient-to-r from-[#6a0dad] to-[#69c0f4] inline-block text-transparent bg-clip-text">cheapest</span> and <span className="bg-gradient-to-r from-[#6a0dad] to-[#69c0f4] inline-block text-transparent bg-clip-text">fastest</span> path to production
                        </p>
                        <p className="text-gray-900 leading-relaxed text-lg text-white mb-9 mx-auto sm:px-72 2xl:px-96">
                            Fino is a platform that allows developers focus on building rather than on deploying.
                            <a className="font-medium mx-1">Cheap prices + unparalleled developer experience</a> so that you can deploy your
                            web services in minutes
                        </p>
                        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                                <button onClick={user ? goToDashboard : signInWithGitHub}
                                    className="bg-gradient-to-r from-[#6a0dad] to-[#69c0f4] text-white px-6 py-3 rounded-full font-semibold hover:bg-opacity-80 transition-colors">
                                    {user ? "Go to dashboard" : "Get Started for Free"}
                                </button>
                            <a target="_blank" href="https://youtu.be/9nO4jN6Q3c4" class="relative bg-transparent border-2 border-purple-800 border-r-blue-400 text-white px-6 py-3 rounded-full font-semibold transition-all hover:border-opacity-80">
                                <a class="relative">See demo</a>
                            </a>
                        </div>
                    </div>
                </section>
                <div className="mt-56 py-12 px-6 rounded-lg max-w-screen-lg mx-auto">
                    <h2 className="text-gray-300 leading-relaxed text-3xl font-bold mb-4 text-center">Fino vs Render vs AWS</h2>
                    <p className="mb-6 text-gray-300 leading-relaxed text-center text-lg">
                        Fino offers the cheapest option while being easy to use
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {pricingData.map((row, index) => (
                            <div key={index} className="bg-white/10 text-gray-800 border border-white/20 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
                                <h3 className="text-xl text-white font-semibold mb-2">{row.tier}</h3>
                                <p className="flex text-white items-center mb-2"><MemoryStickIcon className="inline-block text-purple-500 mr-1" />{row.ram} RAM</p>
                                <p className="flex text-white items-center mb-2"><Cpu className="inline-block text-purple-500 mr-1" />{row.cpu} CPU</p>
                                <div className="mt-4">
                                    <div className="flex justify-between mb-2">
                                        <span className="text-gray-200 font-semibold">Fino: {row.coolCloudPrice} <a className="rounded p-1 border border-green-500 text-green-500 text-xs">Cheapest</a> </span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-gray-200 font-semibold">AWS: {row.awsPrice}</span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-gray-200 font-semibold">Render: {row.renderPrice}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>    
                <div className="p-8 min-h-screen flex items-center">
                    <div className="container mx-auto max-w-6xl">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                            <div>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                >
                                    <XAxis
                                        dataKey="month"
                                        stroke="#9CA3AF"
                                    />
                                    <YAxis
                                        stroke="#9CA3AF"
                                        tickFormatter={(value) => `$${value}`}
                                    />
                                    <Tooltip
                                        contentStyle={{
                                            backgroundColor: '#1F2937',
                                            border: 'none',
                                            borderRadius: '8px',
                                            color: '#fff'
                                        }}
                                    />
                                    <Legend
                                        wrapperStyle={{
                                            color: '#9CA3AF'
                                        }}
                                    />
                                    <Bar
                                        dataKey="Side Project"
                                        stackId="a"
                                        fill="#4C1D95"
                                    />
                                    <Bar
                                        dataKey="Company Website"
                                        stackId="a"
                                        fill="#5B21B6"
                                    />
                                    <Bar
                                        dataKey="My Blog"
                                        stackId="a"
                                        fill="#7C3AED"
                                    />
                                </BarChart>
                            </div>

                            <div className="text-white space-y-6">
                                <div className="space-y-2">
                                    <p className="text-purple-400 text-lg">Deploy economically</p>
                                    <h2 className="text-4xl font-bold">Pay as you go.</h2>
                                </div>
                                <p className="text-gray-300 leading-relaxed text-lg">
                                    You only pay for the resources your service actually uses, not afixed subscription.
                                </p>
                                <button className="bg-white text-[#0a051d] px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition-colors">
                                    Deploy Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-[#0a051d]/90 text-white p-6 max-w-screen-lg mt-32 mx-auto rounded-lg shadow-lg">
                    <p className="text-3xl text-gray-300 leading-relaxed px-7 md:text-5xl font-semibold mb-6">
                        First Class Developer Experience, <br/>Out of The Box.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {/* CI/CD Section */}
                        <div className="p-7 hover:bg-gray-800 rounded-lg shadow-lg">
                            <div className="mb-2">
                                <CloudLightning className="text-3xl text-purple-400" />
                                <h2 className="py-2 text-xl font-semibold">CI/CD without configuration</h2>
                            </div>
                            <p className="">
                                Your service has a new version to deploy? Just push the code to GitHub, and the new version will be automatically online.
                            </p>
                        </div>

                        {/* Auto Scaling Section */}
                        <div className="p-7 hover:bg-gray-800 rounded-lg shadow-lg">
                            <div className="mb-2">
                                <RefreshCcw className="text-3xl text-purple-400" />
                                <h2 className="py-2 text-xl font-semibold">Auto Scaling</h2>
                            </div>
                            <p>
                                As your service needs more and more demand, we can automatically expand the resources it needs.
                            </p>
                        </div>

                        {/* Multi Environment Section */}
                        <div className="p-7 hover:bg-gray-800 rounded-lg shadow-lg">
                            <div className="mb-2">
                                <Briefcase className="text-3xl text-purple-400" />
                                <h2 className="py-2 text-xl font-semibold">Multi Environment</h2>
                            </div>
                            <p>
                                Easily copy your entire architecture into multiple different environments, so your team can develop and test in isolated environments!
                            </p>
                        </div>

                        {/* Object Storage Service Section */}
                        <div className="p-7 hover:bg-gray-800 rounded-lg shadow-lg">
                            <div className="mb-2">
                                <Package className="text-3xl text-purple-400" />
                                <h2 className="py-2 text-xl font-semibold">Object Storage Service</h2>
                            </div>
                            <p>
                                Store files uploaded by users from your backend program, just like S3 or GCS, but simpler!
                            </p>
                        </div>

                        {/* Variables and Configs Section */}
                        <div className="p-7 hover:bg-gray-800 rounded-lg shadow-lg">
                            <div className="mb-2">
                                <Settings className="text-3xl text-purple-400" />
                                <h2 className="py-2 text-xl font-semibold">Variables and Configs</h2>
                            </div>
                            <p>
                                Manage all the complex variables your system needs in a single configuration file, no more dizzying .env files!
                            </p>
                        </div>

                        {/* Domain Management Section */}
                        <div className="p-7 hover:bg-gray-800 rounded-lg shadow-lg">
                            <div className="mb-2">
                                <Globe className="text-3xl text-purple-400" />
                                <h2 className="py-2 text-xl font-semibold">Domain management</h2>
                            </div>
                            <p>
                                You can generate a domain name for the service you deploy, or use your own domain name and add a CNAME record.
                            </p>
                        </div>
                    </div>
                </div>  

                {/* Steps Section */}
                <div className="relative py-32 px-4 sm:px-6 lg:px-8">
                    <div className="absolute inset-0 pointer-events-none" />
                    <div className="max-w-4xl mx-auto relative z-10">
                        <h2 className="text-4xl font-semibold text-white text-center mb-12">
                            Sample: Deploy Your Node.js App on Fino
                        </h2>
                        <div className="relative">
                            {steps.map((step, index) => (
                                <div key={index} className={`flex items-center mb-12 ${index % 2 === 0 ? '' : 'flex-row-reverse'}`}>
                                    <div className={`w-1/2 ${index % 2 === 0 ? 'pr-8 text-right' : 'pl-8 text-left'}`}>
                                        <h3 className="text-xl font-semibold text-purple-400 mb-2">{step.title}</h3>
                                        <p className="text-gray-300">{step.description}</p>
                                    </div>
                                    <div className="w-12 h-12 flex-shrink-0 mx-4">
                                        <div className="w-12 h-12 border-4 border-purple-900 bg-[#150b38] rounded-full flex items-center justify-center">
                                            <step.icon className="h-6 w-6 text-purple-400" />
                                        </div>
                                    </div>
                                    <div className="w-1/2"></div>
                                </div>
                            ))}
                            <div className="absolute h-full w-0.5 bg-purple-900 left-1/2 top-0 transform -translate-x-1/2"></div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Home;